import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ListingComponent } from './listing/listing.component';
import { EditComponent } from './edit/edit.component';
import { DetailComponent } from './detail/detail.component';
import {NewComponent} from './new/new.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {IonicModule} from '@ionic/angular';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from "@angular/router";
import {QuillModule} from "ngx-quill";


@NgModule({
    declarations: [ListingComponent, EditComponent, DetailComponent, NewComponent],
    exports: [ListingComponent, EditComponent, DetailComponent, NewComponent],
    imports: [
        NgxDatatableModule,
        IonicModule,
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        QuillModule.forRoot(),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class GenericsModule{}
