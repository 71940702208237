import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): boolean {
        if (this.authService.isUserAuthenticated) {
            return this.hasRightsToAccess(state);
        } else {
            this.router.navigate(['']);
            return false;
        }
    }

    hasRightsToAccess(state) {
        console.log(state.url);
        const current_roles = JSON.parse(localStorage.getItem("roles"));
        console.log(current_roles);
        const admin_page = [

        ];
        return true;
    }
}