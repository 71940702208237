import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {NavController, Platform, ToastController} from '@ionic/angular';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {


  @Input() form: FormGroup;
  @Input() data: any;
  @Input() service: any;
  @Input() returnLink: any;
  @Input() properties: any[];
  @Input() sAction: string;

  constructor(private router: Router, private navController: NavController, private platform: Platform, private toastController: ToastController) {
  }

  ngOnInit() {
    if (!this.data) {
      this.router.navigate(['/home']);
    }
  }


  async update() {
    if (!this.form.valid) {
      const toast = await this.toastController.create({
        message: 'Les champs requis ne sont pas tous remplis !',
        duration: 2000,
        color: "danger"
      });
      await toast.present();
      return false;
    }
    this.service.updateItem(this.data.id, this.form.value)
    this.router.navigate([this.returnLink]);
  }

  compareWithFn(o1, o2) {
    return o1 && o2 ? o1 === o2["@id"] : o1 === o2;
  }

  compareWithFnMulti(f1, f2) {
    if (Array.isArray(f2)) {
      if (!f1 || !f1.id) {
        return false;
      }
      return f2.find(val => val && val.id === f1.id);
    }
    return f1 && f2 ? f1.id === f2.id || f1 === f2 : f1 === f2;
  }

  public hasValidator(control: string, validator: string): boolean {
    if (this.form.controls[control].validator != null && this.form.controls[control].validator(this.form.controls[control])) {
      return !!this.form.controls[control].validator(this.form.controls[control]).hasOwnProperty(validator);
    }
    return false;
    // returns true if control has the validator
  }
}
