import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-classification',
  templateUrl: './modal-classification.page.html',
  styleUrls: ['./modal-classification.page.scss'],
})
export class ModalClassificationPage implements OnInit {

  modalTitle: string;
  modelContent: number;

  constructor(
      private modalController: ModalController,
      private navParams: NavParams
  ) { }

  ngOnInit() {
    console.table(this.navParams);
    this.modelContent = this.navParams.data.modelContent;
    this.modalTitle = this.navParams.data.paramTitle;
  }

  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

}
