import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpErrorResponse, HttpClientModule} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {retry, catchError, map} from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import {LoadingController, ToastController} from "@ionic/angular";
import {AuthService} from "./auth.service";



@Injectable({
    providedIn: 'root'
})
export abstract class MasterService {
    constructor(
        protected http: HttpClient,
        protected toastController: ToastController,
        protected loadingController: LoadingController,
        protected authService: AuthService
    ) { }

    // Dynamise l'URL pour les appels à l'API
    url = environment.url;
    // Argument de l'URL permettant de se situer dans l'application (exemple : 'https://127.0.0.1:8000/api/causes')
    protected entity: string;
    itemSubject = new Subject<any[]>();
    // Récupère un tableau de model (ex: causesModel[] = [])
    protected items: any[] = [];


    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*',
            //'Authorization': this.authService.jwtToken
            // 'Authorization': 'bearer ' + this.authService.token
        })
    };

    emitSubject() {
        this.itemSubject.next(this.items.slice());
    }

    // Handle API errors
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.log(error);
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }

    createItem(item) {
        return this.http
            .post<any>(this.url + '/' + this.entity, JSON.stringify(item), this.httpOptions).subscribe(
                data => {
                    // this.items = [];
                    // this.getList();
                    this.items.push(data);
                    this.emitSubject();
                },
                error => {
                    retry(2),
                        catchError(this.handleError);
                }
            );
    }

    getList() {

        let httpOptionsGet = {
            headers: new HttpHeaders({
                'authorization': this.authService.jwtToken
                // 'Authorization': 'bearer ' + this.authService.token
            })
        };

        return this.http.get(this.url + '/' + this.entity, httpOptionsGet)
            // Le pipe filtre ce dont on a besoin, il attend la fin de la première partie pour exécuter la suivante.
            // Ici il attend le retour de http pour executer le .map()
            .pipe(map( res => {
                this.items = res['hydra:member'];
                //console.log('service list '+this.entity, this.items);
            }))
            .subscribe(
                reslt => {
                    this.emitSubject();
                },
                error => {
                    if (error.error.message == "Expired token") {
                        this.authService.refreshJwtToken().subscribe();
                    }
                    console.log("error",error);
                    retry(2);
                    catchError(this.handleError);
                }
            );

    /*.subscribe(
                result => {
                    console.log(result)
                    this.items = Object.assign(this.items, result);
                    this.emitSubject();
                },
                error => {
                    if (error.error.message == "Expired token") {
                        this.authService.refreshJwtToken().subscribe();
                    }
                    console.log("error",error);
                    retry(2);
                    //catchError(this.handleError);
                }
            );*/
    }

    getItem(id: number) {
        return this.items.find(
            (obj) => {
                //console.log(obj);
                return +obj.id === +id;
            }
        );
    }
    // Get detail
    getItemFromServer(id): Observable<any> {
        return  this.http
            .get<any>(this.url + '/' + this.entity + '/' + id)
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }


    updateItem(id, item) {
        this.presentLoading();

        let httpOptionsPut = {
            headers: new HttpHeaders({
                'authorization': this.authService.jwtToken,
                'Content-Type': 'application/json',
                //'Access-Control-Allow-Origin':'*',
                // 'Authorization': 'bearer ' + this.authService.token
            })
        };

        this.http.put(this.url + '/' + this.entity + '/' + id, JSON.stringify(item), httpOptionsPut).subscribe(
            data => {
                // this.items = [];
                // this.getList();
                const indexItem = this.items.findIndex(item => item.id === id);
                this.items[indexItem] = data;
                this.items = [...this.items];
                this.emitSubject();
                this.presentToastSuccess();
            },
            error => {
                retry(2),
                    catchError(this.handleError);
                this.presentToastError();
            }
        );
    }

    // Delete item by id
    deleteItem(id) {
        return this.http
            .delete(this.url + '/' + this.entity + '/' + id, this.httpOptions).subscribe( res => {
                    // ici je ne refais pas de méthode GET mais j'enleve par splice l'objet supprimé
                    this.items.splice(this.items.findIndex(item => item.id === id), 1);
                    // à  chaque modification d'objet, il faut emettre le sujet
                    this.emitSubject();
                    this.presentToastSuccess();
                }, error => {
                    retry(2),
                        catchError(this.handleError);
                    this.presentToastError();
                }
            );
    }

    async presentToastSuccess() {
        const toast = await this.toastController.create({
            message: 'La modification a bien été effectuée',
            duration: 2000,
            color: "success"
        });
        await toast.present();
    }

    async presentToastError() {
        const toast = await this.toastController.create({
            message: 'Une erreur est survenue',
            duration: 2000,
            color: "danger"
        });
        await toast.present();
    }

    async presentLoading() {
        const loading = await this.loadingController.create({
            cssClass: 'my-custom-class',
            message: 'Patientez svp ...',
            duration: 3000
        });
        await loading.present();

        const { role, data } = await loading.onDidDismiss();
    }

    createSub(item) {
        console.log("create", item);
        return this.http
            .post<any>(this.url + '/' + this.entity, JSON.stringify(item), this.httpOptions);
    }

    filterDonnee(temp, val, testFilter) {
        let test = false;
        return this.items.filter(function (d) {
                test = false;
                testFilter.forEach(c => {
                        if (c.type !== 'select' && c.type !== 'bool') {
                            if (d[c.name].toString().toLowerCase().indexOf(val) !== -1 || !val) {
                                test = true;
                            }
                        } else if(c.type === 'select') {
                            if (d[c.name][c.field].toString().toLowerCase().indexOf(val) !== -1 || !val) {
                                test = true;
                            }
                        }
                    }
                );
                return test;
            });
    }
}
