import { Component, OnInit, Input } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {

  @Input() data: any;
  @Input() infoToDisplay: any[];

  constructor(private router: Router) {}

  ngOnInit() {
    if (!this.data) {
      this.router.navigate(['/home']);
    }

    // console.log(this.data);
    // console.log(this.infoToDisplay);
  }

}
