import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {NavController, Platform, ToastController} from '@ionic/angular';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss'],
})
export class NewComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() data: any;
  @Input() service: any;
  @Input() returnLink: any;
  @Input() properties: any[];
  @Input() sAction: string;

  constructor(private router: Router, private navController: NavController, private platform: Platform, private toastController: ToastController) {

  }

  ngOnInit() { }

  async create() {
    // à la soumission du formulaire si l'utilisateur n'a pas touché au champ actif pour le laisser inactif
    // lui passer une valeur à false, sinon erreur d'envoi car actif sera égal à undefined
    // plus necessaire avec modification de la methode create où on ne refait pas de requete au serveur on push le nouvel element dans l'objt
    // if (this.form.get('actif').value === undefined) {
    //   this.form.patchValue({actif: false});
    //   console.log(this.form.value);
    // }
    if (!this.form.valid) {
      const toast = await this.toastController.create({
        message: 'Les champs requis ne sont pas tous remplis !',
        duration: 2000,
        color: "danger"
      });
      await toast.present();
      return false;
    }

    console.log(this.form.value);
    this.service.createItem(this.form.value);

    this.router.navigate([this.returnLink]);
  }

  public hasValidator(control: string, validator: string): boolean {
    if (this.form.controls[control].validator != null && this.form.controls[control].validator(this.form.controls[control])) {
      return !!this.form.controls[control].validator(this.form.controls[control]).hasOwnProperty(validator);
    }
    return false;
    // returns true if control has the validator
  }
}
