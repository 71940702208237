import { Injectable } from '@angular/core';
import {MasterService} from './master.service';
import { IdentifiantService } from '../models/identifiant-service.model';

@Injectable({
  providedIn: 'root'
})
export class IdentifiantServiceService extends MasterService {
	entity = 'logins';
	protected items: IdentifiantService[] = [];
}
