import {AfterContentInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertController, ModalController} from '@ionic/angular';
import {ModalClassificationPage} from "../../modals/modal-classification/modal-classification.page";
import {DatatableComponent, SortType} from "@swimlane/ngx-datatable";
import {ModalResaIpPage} from "../../modals/modal-resa-ip/modal-resa-ip.page";
import {ModalLoginsPage} from "../../modals/modal-logins/modal-logins.page";
import {AuthService} from "../../services/auth.service";


@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss'],
})
export class ListingComponent{
  @Input() row = [];
  @Input() col: any;
  @Input() aActionsNav: any[];
  @Input() oService: any;
  @Input() aActions: any[];
  @Input() showLogins: boolean;
  @Input() isNotFilter: boolean;

  private dataReturned: any;
  temp = [];
  private SortType = SortType;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
      private router: Router,
      private alertCtrl: AlertController,
      public modalController: ModalController,
      private authService: AuthService
  ) {}

  onDelete(id: number, service: any) {
    this.alertCtrl.create({
      header: 'Confirmation de suppression',
      message: 'Voulez-vous vraiment supprimer l\'élement ?',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel'
        },
        {
          text: 'supprimer',
          handler: () => {
            service.deleteItem(id);
          }
        }
      ]
      // Create renvoie une promise
    }).then(alertEl => {
      // Promise vide avec present
      alertEl.present();
    });
  }

  navigateTop(args: any[]) {
    this.router.navigate(args);
  }

  exportPDF() {
    console.log('export pdf');
  }

  exportEXCEL() {
    console.log('export excel');
  }

  async openModal(title, content) {
    const modal = await this.modalController.create({
      component: ModalClassificationPage,
      componentProps: {
        "modelContent": content,
        "paramTitle": title
      }
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });

    return await modal.present();
  }

  updateFilter(event) {
    if (this.temp.length === 0) {
      this.temp = [...this.row];
    }
    const val = event.target.value.toLowerCase();

    let testFilter = this.col;

    const temp = this.oService.filterDonnee(this.temp, val, testFilter);

    // update the rows
    this.row = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  openLoginsModal(event) {

  }

  async openLoginModal(i) {
    let objectToShow = this.row.filter((x) => {
      return x.id === i;
    })[0];
    console.log(objectToShow);
    const modal = await this.modalController.create({
      component: ModalLoginsPage,
      componentProps: {
        "logins": objectToShow.login
      }
    });

    return await modal.present();
  }

    onChangePassword(item: any, service: any) {
      this.alertCtrl.create({
        header: 'Changement du mot de passe',
        message: 'Voulez-vous vraiment changer le mot de passe ?',
        inputs: [
          {
            name: 'password',
            placeholder: 'Nouveau mot de passe',
            type: 'password'
          }
        ],
        buttons: [
          {
            text: 'Annuler',
            role: 'cancel'
          },
          {
            text: 'Changer le mot de passe',
            handler: (data) => {

              service.changeItemPassword(item, data.password);
            }
          }
        ]
        // Create renvoie une promise
      }).then(alertEl => {
        // Promise vide avec present
        alertEl.present();
      });
    }

    isAdmin() {
      return this.authService.roles.indexOf("ROLE_ADMIN") !== -1;
    }
}
