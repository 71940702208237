import { Component, OnInit } from '@angular/core';
import {Ip} from "../../models/ip.model";
import {ModalController, NavParams} from "@ionic/angular";
import {IdentifiantServiceService} from "../../services/IdentifiantService.service";

@Component({
  selector: 'app-modal-logins',
  templateUrl: './modal-logins.page.html',
  styleUrls: ['./modal-logins.page.scss'],
})
export class ModalLoginsPage implements OnInit {

  logins: any;

  aCol: any[] = [
    {name: 'login', label: 'Login'},
    {name: 'password', label: 'Password'},
    {name: 'loginService', type:'selectmulti', field : 'name', label: 'Services associés'},
  ];
  aActions: any[] = [
    /*{type: 'update', name: 'Edit', routerLink: '/edit-identifiant-service'},
    {type: 'details', name: 'Details', routerLink: '/detail-identifiant-service'},
    {type: 'delete', name: 'Delete', actionService: this.service}*/
  ];
  aActionsNav: any[] = [
    /*{name: 'Ajouter', action: 'NAV', argument: ['new-identifiant-service']},
    {name: 'Export PDF', action: 'PDF', argument: ['PDF']},
    {name: 'Export EXCEL', action: 'EXCEL', argument: ['EXCEL']}*/
  ];

  constructor(
      private modalController: ModalController,
      private navParams: NavParams,
      private service: IdentifiantServiceService
  ) { }

  ngOnInit() {
    //this.modelContent = this.navParams.data.addresseIP;
  }

  ionViewDidEnter() {
    this.logins = this.navParams.data.logins;
    //this.modelContent = this.navParams.data.addresseIP;
  }

  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

}
