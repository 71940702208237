import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import {Capacitor, Plugins} from "@capacitor/core";
import {Router} from "@angular/router";
import {AuthService} from "./services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  pages = [
    {
      title: 'Accueil',
      url: '/home',
      icon: 'home'
    },
    {
      title: '1 - Clients',
      url: '/parametrage/client-tab/list-client',
      icon: 'body-outline'
    },
    {
      title: '2 - Adresses IP',
      url: '/parametrage/ip-tab/list-pool',
      icon: 'swap-horizontal-outline'
    },
    {
      title: '3 - Disques Durs',
      url: '/listing-disk',
      icon: 'cloud-download-outline'
    },
    {
      title: '4 - Accès machine/applicatif',
      url: '/parametrage/login-tab/list-identifiant-service',
      icon: 'key-outline'
    },
    {
      title: '5 - Machines Physiques AGIIR',
      url: '/listing-machine-physique',
      icon: 'desktop-outline'
    },
    {
      title: '6 - Sauvegardes',
      url: '/parametrage/backup-tab/list-backup',
      icon: 'save-outline'
    },
    {
      title: '7 - VMs AGIIR',
      url: '/listing-vm',
      icon: 'desktop-outline'
    },
    {
      title: '8 - Services AGIIR',
      url: '/parametrage/service-tab/list-site-internet',
      icon: 'globe-outline'
    },
    {
      title: 'Paramétrage',
      icon: 'build-outline',
      children: [
        {
          title: 'OS (systèmes d\'exploitation)',
          url: '/listing-os',
        },
        {
          title: 'Services AGIIR chez OVH',
          url: '/parametrage/ovh-service-tab/list-ovh-service',
        },
        {
          title: 'Emplacement OVH',
          url: '/parametrage/ovh-site-tab/list-localisation',
        },
        {
          title: 'Utilisateurs',
          url: '/list-user',
        },
        /*{
          title: 'Clients & Compta',
          url: '/parametrage/compta-tab/list-customer',
        },*/
      ]
    },
  ];

  constructor(
      private platform: Platform,
      private router: Router,
      private authService: AuthService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (Capacitor.isPluginAvailable('SplashScreen')) {
        Plugins.SplashScreen.hide();
      }
    });
  }

  onLogout() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  ngOnInit() {

  }
}
