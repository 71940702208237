import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from "./guard/auth.guard";

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  /*{
    path: 'listing-site',
    loadChildren: () => import('./site/listing-site/listing-site.module').then( m => m.ListingSitePageModule)
  },*/
  {
    path: 'edit-site',
    loadChildren: () => import('./site/edit-site/edit-site.module').then( m => m.EditSitePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-site',
    loadChildren: () => import('./site/new-site/new-site.module').then( m => m.NewSitePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-site',
    loadChildren: () => import('./site/detail-site/detail-site.module').then( m => m.DetailSitePageModule),
    canActivate: [AuthGuard]
  },
  /*{
    path: 'listing-localisation',
    loadChildren: () => import('./localisation/listing-localisation/listing-localisation.module').then( m => m.ListingLocalisationPageModule)
  },*/
  {
    path: 'edit-localisation',
    loadChildren: () => import('./localisation/edit-localisation/edit-localisation.module').then( m => m.EditLocalisationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-localisation',
    loadChildren: () => import('./localisation/new-localisation/new-localisation.module').then( m => m.NewLocalisationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-localisation',
    loadChildren: () => import('./localisation/detail-localisation/detail-localisation.module').then( m => m.DetailLocalisationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'parametrage',
    loadChildren: () => import('./parametrage/parametrage.module').then( m => m.ParametragePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ovh-site-tab',
    loadChildren: () => import('./parametrage/ovh-site-tab/ovh-site-tab.module').then( m => m.OvhSiteTabPageModule),
    canActivate: [AuthGuard]
  },
  /*{
    path: 'listing-ovh-type-service',
    loadChildren: () => import('./ovh-type-service/listing-ovh-type-service/listing-ovh-type-service.module').then( m => m.ListingOvhTypeServicePageModule)
  },*/
  {
    path: 'edit-ovh-type-service',
    loadChildren: () => import('./ovh-type-service/edit-ovh-type-service/edit-ovh-type-service.module').then( m => m.EditOvhTypeServicePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-ovh-type-service',
    loadChildren: () => import('./ovh-type-service/new-ovh-type-service/new-ovh-type-service.module').then( m => m.NewOvhTypeServicePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-ovh-type-service',
    loadChildren: () => import('./ovh-type-service/detail-ovh-type-service/detail-ovh-type-service.module').then( m => m.DetailOvhTypeServicePageModule),
    canActivate: [AuthGuard]
  },
  /*{
    path: 'listing-ovh-service',
    loadChildren: () => import('./ovh-service/listing-ovh-service/listing-ovh-service.module').then( m => m.ListingOvhServicePageModule)
  },*/
  {
    path: 'edit-ovh-service',
    loadChildren: () => import('./ovh-service/edit-ovh-service/edit-ovh-service.module').then( m => m.EditOvhServicePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-ovh-service',
    loadChildren: () => import('./ovh-service/new-ovh-service/new-ovh-service.module').then( m => m.NewOvhServicePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-ovh-service',
    loadChildren: () => import('./ovh-service/detail-ovh-service/detail-ovh-service.module').then( m => m.DetailOvhServicePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'listing-os',
    loadChildren: () => import('./os/listing-os/listing-os.module').then( m => m.ListingOsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-os',
    loadChildren: () => import('./os/edit-os/edit-os.module').then( m => m.EditOsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-os',
    loadChildren: () => import('./os/new-os/new-os.module').then( m => m.NewOsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-os',
    loadChildren: () => import('./os/detail-os/detail-os.module').then( m => m.DetailOsPageModule),
    canActivate: [AuthGuard]
  },
  /*{
    path: 'listing-pool',
    loadChildren: () => import('./pool/listing-pool/listing-pool.module').then( m => m.ListingPoolPageModule)
  },*/
  {
    path: 'edit-pool',
    loadChildren: () => import('./pool/edit-pool/edit-pool.module').then( m => m.EditPoolPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-pool',
    loadChildren: () => import('./pool/new-pool/new-pool.module').then( m => m.NewPoolPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-pool',
    loadChildren: () => import('./pool/detail-pool/detail-pool.module').then( m => m.DetailPoolPageModule),
    canActivate: [AuthGuard]
  },
  /*{
    path: 'listing-ip',
    loadChildren: () => import('./ip/listing-ip/listing-ip.module').then( m => m.ListingIpPageModule)
  },*/
  {
    path: 'edit-ip',
    loadChildren: () => import('./ip/edit-ip/edit-ip.module').then( m => m.EditIpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-ip',
    loadChildren: () => import('./ip/new-ip/new-ip.module').then( m => m.NewIpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-ip',
    loadChildren: () => import('./ip/detail-ip/detail-ip.module').then( m => m.DetailIpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'listing-disk',
    loadChildren: () => import('./disk/listing-disk/listing-disk.module').then( m => m.ListingDiskPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-disk',
    loadChildren: () => import('./disk/edit-disk/edit-disk.module').then( m => m.EditDiskPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-disk',
    loadChildren: () => import('./disk/new-disk/new-disk.module').then( m => m.NewDiskPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-disk',
    loadChildren: () => import('./disk/detail-disk/detail-disk.module').then( m => m.DetailDiskPageModule),
    canActivate: [AuthGuard]
  },
  /*{
    path: 'listing-client',
    loadChildren: () => import('./client/listing-client/listing-client.module').then( m => m.ListingClientPageModule)
  },*/
  {
    path: 'edit-client',
    loadChildren: () => import('./client/edit-client/edit-client.module').then( m => m.EditClientPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-client',
    loadChildren: () => import('./client/new-client/new-client.module').then( m => m.NewClientPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-client',
    loadChildren: () => import('./client/detail-client/detail-client.module').then( m => m.DetailClientPageModule),
    canActivate: [AuthGuard]
  },
  /*{
    path: 'listing-contrat',
    loadChildren: () => import('./contrat/listing-contrat/listing-contrat.module').then( m => m.ListingContratPageModule)
  },*/
  {
    path: 'edit-contrat',
    loadChildren: () => import('./contrat/edit-contrat/edit-contrat.module').then( m => m.EditContratPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-contrat',
    loadChildren: () => import('./contrat/new-contrat/new-contrat.module').then( m => m.NewContratPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-contrat',
    loadChildren: () => import('./contrat/detail-contrat/detail-contrat.module').then( m => m.DetailContratPageModule),
    canActivate: [AuthGuard]
  },
  /*{
    path: 'listing-login-service',
    loadChildren: () => import('./login-service/listing-login-service/listing-login-service.module').then( m => m.ListingLoginServicePageModule)
  },*/
  {
    path: 'edit-login-service',
    loadChildren: () => import('./login-service/edit-login-service/edit-login-service.module').then( m => m.EditLoginServicePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-login-service',
    loadChildren: () => import('./login-service/new-login-service/new-login-service.module').then( m => m.NewLoginServicePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-login-service',
    loadChildren: () => import('./login-service/detail-login-service/detail-login-service.module').then( m => m.DetailLoginServicePageModule),
    canActivate: [AuthGuard]
  },
  /*{
    path: 'listing-identifiant-service',
    loadChildren: () => import('./identifiant-service/listing-identifiant-service/listing-identifiant-service.module').then( m => m.ListingIdentifiantServicePageModule)
  },*/
  {
    path: 'edit-identifiant-service',
    loadChildren: () => import('./identifiant-service/edit-identifiant-service/edit-identifiant-service.module').then( m => m.EditIdentifiantServicePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-identifiant-service',
    loadChildren: () => import('./identifiant-service/new-identifiant-service/new-identifiant-service.module').then( m => m.NewIdentifiantServicePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-identifiant-service',
    loadChildren: () => import('./identifiant-service/detail-identifiant-service/detail-identifiant-service.module').then( m => m.DetailIdentifiantServicePageModule),
    canActivate: [AuthGuard]
  },
  /*{
    path: 'listing-backup',
    loadChildren: () => import('./backup/listing-backup/listing-backup.module').then( m => m.ListingBackupPageModule)
  },*/
  {
    path: 'edit-backup',
    loadChildren: () => import('./backup/edit-backup/edit-backup.module').then( m => m.EditBackupPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-backup',
    loadChildren: () => import('./backup/new-backup/new-backup.module').then( m => m.NewBackupPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-backup',
    loadChildren: () => import('./backup/detail-backup/detail-backup.module').then( m => m.DetailBackupPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'listing-machine-physique',
    loadChildren: () => import('./machine-physique/listing-machine-physique/listing-machine-physique.module').then( m => m.ListingMachinePhysiquePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-machine-physique',
    loadChildren: () => import('./machine-physique/edit-machine-physique/edit-machine-physique.module').then( m => m.EditMachinePhysiquePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-machine-physique',
    loadChildren: () => import('./machine-physique/new-machine-physique/new-machine-physique.module').then( m => m.NewMachinePhysiquePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-machine-physique',
    loadChildren: () => import('./machine-physique/detail-machine-physique/detail-machine-physique.module').then( m => m.DetailMachinePhysiquePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'listing-vm',
    loadChildren: () => import('./vm/listing-vm/listing-vm.module').then( m => m.ListingVmPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-vm',
    loadChildren: () => import('./vm/edit-vm/edit-vm.module').then( m => m.EditVmPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-vm',
    loadChildren: () => import('./vm/new-vm/new-vm.module').then( m => m.NewVmPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-vm',
    loadChildren: () => import('./vm/detail-vm/detail-vm.module').then( m => m.DetailVmPageModule),
    canActivate: [AuthGuard]
  },
  /*{
    path: 'listing-backup-task',
    loadChildren: () => import('./backup-task/listing-backup-task/listing-backup-task.module').then( m => m.ListingBackupTaskPageModule)
  },*/
  {
    path: 'edit-backup-task',
    loadChildren: () => import('./backup-task/edit-backup-task/edit-backup-task.module').then( m => m.EditBackupTaskPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-backup-task',
    loadChildren: () => import('./backup-task/new-backup-task/new-backup-task.module').then( m => m.NewBackupTaskPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-backup-task',
    loadChildren: () => import('./backup-task/detail-backup-task/detail-backup-task.module').then( m => m.DetailBackupTaskPageModule),
    canActivate: [AuthGuard]
  },
  /*{
    path: 'listing-type-site-internet',
    loadChildren: () => import('./type-site-internet/listing-type-site-internet/listing-type-site-internet.module').then( m => m.ListingTypeSiteInternetPageModule)
  },*/
  {
    path: 'edit-type-site-internet',
    loadChildren: () => import('./type-site-internet/edit-type-site-internet/edit-type-site-internet.module').then( m => m.EditTypeSiteInternetPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-type-site-internet',
    loadChildren: () => import('./type-site-internet/new-type-site-internet/new-type-site-internet.module').then( m => m.NewTypeSiteInternetPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-type-site-internet',
    loadChildren: () => import('./type-site-internet/detail-type-site-internet/detail-type-site-internet.module').then( m => m.DetailTypeSiteInternetPageModule),
    canActivate: [AuthGuard]
  },
  /*{
    path: 'listing-site-internet',
    loadChildren: () => import('./site-internet/listing-site-internet/listing-site-internet.module').then( m => m.ListingSiteInternetPageModule)
  },*/
  {
    path: 'edit-site-internet',
    loadChildren: () => import('./site-internet/edit-site-internet/edit-site-internet.module').then( m => m.EditSiteInternetPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-site-internet',
    loadChildren: () => import('./site-internet/new-site-internet/new-site-internet.module').then( m => m.NewSiteInternetPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-site-internet',
    loadChildren: () => import('./site-internet/detail-site-internet/detail-site-internet.module').then( m => m.DetailSiteInternetPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modal-resa-ip',
    loadChildren: () => import('./modals/modal-resa-ip/modal-resa-ip.module').then( m => m.ModalResaIpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modal-logins',
    loadChildren: () => import('./modals/modal-logins/modal-logins.module').then( m => m.ModalLoginsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-user/:id',
    loadChildren: () => import('./user/detail-user/detail-user.module').then( m => m.DetailUserPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'list-user',
    loadChildren: () => import('./user/list-user/list-user.module').then( m => m.ListUserPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-user/:id',
    loadChildren: () => import('./user/edit-user/edit-user.module').then( m => m.EditUserPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-user',
    loadChildren: () => import('./user/new-user/new-user.module').then( m => m.NewUserPageModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
