import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // j'initalise une variable à false
  isUserAuthenticated = false;
  roles = [];
  url = environment.url;
  private jwt= '';

  // je crée un getter
  get userAuthenticated() {
    return this.isUserAuthenticated;
  }

  get jwtToken() {
    return this.jwt;
  }

  constructor(private http: HttpClient, public router: Router) {}

  login(credentials) {

    this.isUserAuthenticated = false;
    this.jwt = '';

    let httpObservable = this.http.post(this.url + '/login', credentials).pipe(
        tap(res => {
          //console.log(res);

          if (typeof res['token'] === 'undefined') {
            this.isUserAuthenticated = false;
          } else {
            this.isUserAuthenticated = true;
            this.roles = res['roles'];
            localStorage.setItem('jwt', res['token']);
            localStorage.setItem('roles', JSON.stringify(res['roles']));
            this.jwt = res['token'];
          }
        })
    );

    return httpObservable;
  }

  refreshJwtToken() {
    //console.log("refresh jwt");
    return this.http.post(this.url + '/refresh', {'token' : this.jwt}).pipe(
        tap(res => {
          //console.log('refresh', res);

          if (typeof res['token'] === 'undefined') {
            this.isUserAuthenticated = false;
            this.router.navigate(['/login'])
          } else {
            this.isUserAuthenticated = true;
            localStorage.setItem('jwt', res['token']);
            this.jwt = res['token'];
          }
        })
    );
  }

  logout() {
    this.isUserAuthenticated = false;
    localStorage.setItem('jwt', '');
    localStorage.setItem('roles', '');
    this.roles = [];
  }
}
